<template>
    <el-dialog append-to-body :width="width" :visible.sync="dialogImageObj.show" @closed="closedBtn">
        <img width="100%" :src="dialogImageObj.url" alt="">
    </el-dialog>
</template>

<script>
export default {
    name: "DialogImage",
    props: {
        dialogImageObj: {
            type: Object,
            default: () => {
                return {
                    show: false,
                    width: "600px",
                    url: ""
                }
            }
        },
        width: {
            type: String,
            default: "600px"
        },
    },
    methods: {
        closedBtn() {
            this.$emit("closed");
        }
    },
}
</script>

<style lang="scss" scoped></style>