<template>
    <div class="navigation">
        <img class="headImg" :src="basicForm.stuImg || avatar" alt="" />
        <div class="nameSty"> {{ basicForm.studentName }}</div>
        <div class="classSty">{{ basicForm.organName }}</div>
        <div class="line"></div>
        <div v-for="(item, index) in tabList" :key="index">
            <div
                :class="['itemSty', { current: item.sel }]"
                @click="changeNavs(item)"
                v-hasPermi="item.vHasPermi"
                >{{ item.title }}</div>
        </div>
    </div>
</template>
<script>
import {mapState} from "vuex";
export default {
    name: "DetailAnchor",
    props: [
        'basicForm',
        'avatar',
        'tabList'
    ],
    computed: {
        ...mapState({
            permissions: (state) => state.permissions,
        }),
    },
    watch: {},
    created() {
    },
    mounted() {},
    methods: {
        changeNavs(item) {
            this.$emit('changeNavs', item)
        }
    }
}
</script>
<style lang="scss" scoped>
//导航
.navigation {
    width: 224px;
    height: 430px;
    background: #FFFFFF;
    box-shadow: -2px 0px 8px 0px #E1E3E6;
    border-radius: 8px;
    text-align: center;
    padding: 0px 16px;
    box-sizing: border-box;
    margin-right: 14px;
    .headImg {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        padding: 30px 0 20px 0;
    }
    .nameSty {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #2B2E33;
    }
    .classSty {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #808387;
        padding: 14px 0 32px 0;
    }
    .line {
        width: 100%;
        height: 1px;
        background: #EBEBEB;
        margin: 0 auto;
    }
    .itemSty {
        width: 192px;
        // height: 40px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 15px;
        color: #2B2E33;
        margin-top: 8px;
        line-height: 40px;
        cursor: pointer;
        &:hover {
            background: #F2F2F2;
            border-radius: 4px;
        }
    }
    .itemSty.current {
        background: #3C7FFF;
        color: #FFFFFF;
        border-radius: 4px;

    }
}
</style>
