import { render, staticRenderFns } from "./DetailAnchor.vue?vue&type=template&id=5445334f&scoped=true"
import script from "./DetailAnchor.vue?vue&type=script&lang=js"
export * from "./DetailAnchor.vue?vue&type=script&lang=js"
import style0 from "./DetailAnchor.vue?vue&type=style&index=0&id=5445334f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5445334f",
  null
  
)

export default component.exports