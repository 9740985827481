<template>
    <div class="common-form-anchor">
        <div class="common-form-anchor-layout-scroll">
            <form-anchor-item
                v-for="(item, index) of anchorList"
                :data="item"
                :key="index"
            >
            </form-anchor-item>
        </div>
    </div>
</template>
<script>
import FormAnchorItem from './Item.vue';
export default {
    name: "FormAnchor",
    components: {
        FormAnchorItem
    },
    props: {
        anchorList: Array
    }
}
</script>
<style lang="scss" scoped>
.common-form-anchor-layout-scroll{
    height: calc(100vh - 217px);
    overflow-y: auto;
    scrollbar-gutter: stable;
}
    .form-anchor {
        box-sizing: border-box;
        padding: 15px 0;
        box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.1);
        border-radius: 4px;
        // background-color: #FFFFFF;
    }
</style>
