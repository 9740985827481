<template>
    <a class="form-anchor-item" :class="{ 'form-anchor-item_active': data.active }" href="javascript:;" @click="handlerFormScroll(data.key)">
        <div class="form-anchor-info">
            <div class="line"></div>
            <el-tooltip class="item" effect="dark" :content="data.title" placement="top-start" :disabled="data.title.length <= 5">
                <div class="title">{{ data.title.length > 5 ? data.title.slice(0, 5) + '...' : data.title }}</div>
            </el-tooltip>
        </div>
        <div class="rules">
            <div v-if="data.finish" class="finish-icon">
                <img class="finish-img" :src="finishImg" alt="finish">
            </div>
            <div :class="['rules-type',{current: data.finish}]">
                <span v-if="!data.finish">{{ data.text }}</span>
                <span v-else>已完成</span>
            </div>
        </div>
    </a>
</template>
<script>
export default {
    name: "FormAnchorItem",
    props: {
        data: Object
    },
    computed: {
        finishImg () {
            return require('@/assets/images/form-rules-finish.svg')
        }
    },
    methods: {
        handlerFormScroll (key) {
            if (document.querySelector(key) && !this.data.change) {
                document.querySelector(key).scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            }
            this.$eventDispatch('handlerFormScroll', this.data)
        }
    }
}
</script>
<style lang="scss" scoped>
    .form-anchor-item {
        height: 47.6px;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .form-anchor-info {
            flex: 1;
            display: flex;
            align-items: center;
            .line {
                width: 3px;
                height: 14px;
                margin: 0 8px 0 12px;
                background-color: #3C7FFF;
            }
            .title {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #2B2F33;
                text-align: left;
                flex: 1;
                width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .rules {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            padding-right: 16px;

            .finish-icon {
                width: 13px;
                height: 13px;
                margin-top: -5px;
                margin-right: 4px;
                .finish-img {
                    width: 100%;
                    height: 100%;
                }
            }
            .rules-type {
                line-height: 24px;
                color: #3C7FFF;

            }
            .rules-type.current span{
                display: block;
                    color: #46D8AB;
                }
        }
    }
    .form-anchor-item:hover,
    .form-anchor-item_active {
        background-color: #F4F4F4;
    }
</style>
